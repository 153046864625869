/** @jsx jsx */
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { jsx } from "@emotion/core"
import PropTypes from "prop-types"
import { langNavigate } from "../../../../helpers"

import Container from "../../../Container"
import NoResults from "../../../NoResults"

import Loading from "../../../../assets/images/svg/loading.svg"

import {
  getInstitutionCampaignsList,
  chooseDestination,
  chooseCampaign,
} from "../../../../state/app"

import {CampaignImage,Card,CampaignDetails,CardsGrid,CustomButton} from "../../Churchs/List/styles"

const renderItems = (list, setWorks, translation) => {
  if (!list.length) {
    return <NoResults translation={translation} />
  }

  return list.map((item, index) => (
    <Card key={index} onClick={() => setWorks(item)}>
      <CampaignImage image={item.image} />
      <CampaignDetails>
        <h3>{item.title}</h3>
        <p></p>
        <CustomButton uppercase color="blue">
          {translation.donateNow}
        </CustomButton>
      </CampaignDetails>
    </Card>
  ))
}

const List = ({ dispatch, translation }) => {
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])

  const setWorks = work => {
    dispatch(
      chooseDestination({
        title: "Templo de Salomão",
        image: "https://s3.us-east-1.amazonaws.com/appuniversal/19491ch",
        id: 5757,
        description: "São Paulo - São Paulo - BR",
      })
    )
    dispatch(
      chooseCampaign({
        imagePath: work.image,
        campaignName: work.title,
        id: work.id,
      })
    )

    langNavigate(6, translation.currentLang)
  }

  useEffect(() => {
    dispatch(getInstitutionCampaignsList())
      .then(({ data: resp }) => {
        setList(
          resp.results.map(
            ({ imagePath, campaignName, institutionName, id }) => ({
              image: imagePath,
              title: campaignName,
              description: institutionName,
              id,
            })
          )
        )
        setLoading(false)
      })
      .catch(() => {
        setTimeout(() => {
          setList([])
          setLoading(false)
        }, 1000)
      })
  }, [dispatch])

  return (
    <>
      <Container>
        <CardsGrid>
          {loading && <Loading role="img" />}
          {!loading && renderItems(list, setWorks, translation)}
        </CardsGrid>
      </Container>
    </>
  )
}

List.propTypes = {
  list: PropTypes.object,
}

export default connect(
  state => ({
    churchList: state.app.churchList,
  }),
  null
)(List)
