/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

import Image from "../../assets/images/background.png"

const Bg = styled.img`
  ${({ theme }) => css`
    position: absolute;
    top: -1270px;
    right: 50%;
    z-index: -1;
    transform: translateX(calc(50% - 40px));

    ${theme.queries.small} {
      transform: translate(calc(50% - 310px));
    }
  `}
`

const Background = () => <Bg src={Image} alt="" />

export default Background
