/** @jsx jsx */
import { jsx, css, keyframes } from "@emotion/core"
import styled from "@emotion/styled"
import { useState, useEffect } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { langNavigate } from "../helpers"
import { routes } from "../locales"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Bg from "../components/Background"
import DonationPower from "../components/pages/Home/DonationPower"

import PageTitle from "../components/PageTitle"
import List from "../components/pages/Campaigns/List"

const People = styled(Img)`
  ${({ theme }) => css`
    width: 578px;
    height: 520px;

    ${theme.queries.small} {
      width: 260px;
      height: 234px;
      margin: 30px auto 0;
      display: block;
    }
  `}
`

const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
    font-size: 38px;
    margin-bottom: 13px;
    display: block;
    text-align: center;
  `}
`

const Description = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    color: ${theme.colors.mediumGrey};
    margin-bottom: 85px;
    display: block;
    text-align: center;

    ${theme.queries.small} {
      margin-bottom: 60px;
    }
  `}
`

const CampaignsPage = ({ pageContext: translation, navigate }) => {
  const [showArrow, setShowArrow] = useState(1)

  const scrollAction = e => {
    //get scroll position
    const el = document.querySelector("html")
    var topWindow = el.scrollTop * 8

    //get height of window
    var windowHeight = el.scrollHeight

    //set position as percentage of how far the user has scrolled
    var position = topWindow / windowHeight
    position = 1 - position

    //define arrow opacity as based on how far up the page the user has scrolled
    //no scrolling = 1, half-way up the page = 0
    setShowArrow(position)
  }

  const mover = keyframes`
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
    `

  const Arrow = styled.span`
    ${({ theme, active }) => css`
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid ${theme.colors.darkGrey};
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 5px;
      opacity: ${showArrow};
      position: fixed;
      left: 50%;
      bottom: 15px;
      transform: translateX(-50%);
      z-index: 3;
      animation: ${mover} 1s infinite alternate;

      span {
        border: solid ${theme.colors.darkGrey};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    `}
  `

  useEffect(() => {
    window.addEventListener("scroll", scrollAction, false)
    return () => {
      window.removeEventListener("scroll", scrollAction, false)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      people: file(relativePath: { eq: "people.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={translation.pageName} />

      <Bg />

      <Title>{translation.helped}</Title>
      <Description>{translation.knowProjects}</Description>

      <List translation={translation} />
      <DonationPower fontDefault={true} translation={translation} />
    </Layout>
  )
}

export default CampaignsPage
